<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-tabs show-arrows center-active v-model="tabSelected">
        <v-tab
          v-for="(tab, index) in tabsOptions"
          :disabled="tab.disabled"
          :key="index"
          :href="`#tab-${index}`"
          style="color: #5c6da7"
        >
          {{ tab.name }}
        </v-tab>
        <v-tab-item
          v-for="(tab, index) in tabsOptions"
          :key="index"
          :value="'tab-' + index"
        >
          <component :ref="tab.ref" :is="tab.component"></component>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>
  <script>
import store from "@/store";
import {
  onUnmounted,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  watch,
  onUpdated,
} from "@vue/composition-api";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "Definicoes-res",
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  setup() {
    onMounted(() => {});
    const tabsOptions = computed(
      () => store.getters["configuracoes/getTabsMenu"]
    );
    const tabSelected = ref(1);
    return {
        tabsOptions,
        tabSelected,

        // 3rd Party
        PerfectScrollbar,
    };
  },
};
</script>
  
  <style>
</style>